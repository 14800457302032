const header = {
  // all the properties are optional - can be left empty or deleted

}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Lee Cheng Xin',
  role: 'Full Stack Developer',
  description:
    "Hi! I'm Cheng Xin. I do code.  I keep a wide array of interests, such as Software Engineering, MLOps, Game Design and Robotic Process Automation",
  social: {
    linkedin: 'https://www.linkedin.com/in/lee-cheng-xin-15680a262/',
    github: 'https://github.com/cleavecamael',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Veritas: Deepfake Audio Detector',
    description:
      "Developed as part of SUTD's Capstone Project, Veritas is a web application allowing users to verify if an audio sample is real or spoofed, and allowing researchers to retrain new models from using a custom dataset.",
    stack: ['Python', 'TypeScript', 'React'],
    sourceCode: 'https://github.com',
    video: "https://youtu.be/bpZROfS0XbA",

  },
  {
    name: 'Studify',
    description:
      "Mobile app that allows students to study together, offline and online. Users can create online study rooms with topics and flashcards, utilizing the Pomodoro technique to ensure study sessions remain productive.",
    stack: ['Android Studio', 'Java'],
    sourceCode: 'https://github.com/kaveripriyap/studify/',
    video: 'https://youtu.be/yd6Z_IPqucg',

  },

  {
    name: 'DubTok',
    description:
      "Web Application that allows users to upload videos, and automatically translates and dubs over the videos, matching the voice and speed of the original",
    stack: ['Python', 'TypeScript', 'React'],
    sourceCode: 'https://github.com/Usgupta/dubtok',
    video: "https://youtu.be/DSHezeGo82c?feature=shared"

  },

]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Python',
  'HTML',
  'CSS',
  'JavaScript',
    'Java',
  'ReactJs',

]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'citrusdoescode@gmail.com',
}

export { header, about, projects, skills, contact }
